import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import Vue from 'vue';

export default function useApplicationsList() {
  const applicationListTable = ref([]);

  const tableColumns = [
    { text: 'Date', value: 'createdAt' },
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Website URL', value: 'website', sortable: false },
    { text: 'Messenger', value: 'messenger', sortable: false },
    { text: 'Account', value: 'account', sortable: false },
    { text: 'Phone', value: 'number', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const searchQuery = ref('');
  const applicationDateFilter = ref(null);
  const applicationDateModal = ref(null);
  const totalApplicationListTable = ref(0);
  const loading = ref(false);
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  });

  // fetch data
  const fetchApplications = () => {
    store
      .dispatch('application/fetchApplications', {
        q: searchQuery.value,
        applicationDate: applicationDateFilter.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
        page: options.value.page,
        itemsPerPage: options.value.itemsPerPage,
      })
      .then(response => {
        applicationListTable.value = response.data.data;
        totalApplicationListTable.value = response.data.totalCount;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Applications',
          text: 'Cannot fetch applications',
        });
      });
  };

  watch([searchQuery, options, applicationDateFilter], () => {
    loading.value = true;
    fetchApplications();
  });

  return {
    applicationDateFilter,
    applicationDateModal,
    applicationListTable,
    tableColumns,
    searchQuery,
    totalApplicationListTable,
    loading,
    options,
    fetchApplications,
  };
}
