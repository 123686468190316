var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"application-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Applications list ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.applicationDateFilter,"width":"290px"},on:{"update:returnValue":function($event){_vm.applicationDateFilter=$event},"update:return-value":function($event){_vm.applicationDateFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"application-date-filter",attrs:{"label":"Select Application date","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.applicationDateFilter),callback:function ($$v) {_vm.applicationDateFilter=$$v},expression:"applicationDateFilter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.applicationDateModal),callback:function ($$v) {_vm.applicationDateModal=$$v},expression:"applicationDateModal"}},[_c('v-date-picker',{staticClass:"event-date-picker",attrs:{"scrollable":"","range":""},model:{value:(_vm.applicationDateFilter),callback:function ($$v) {_vm.applicationDateFilter=$$v},expression:"applicationDateFilter"}},[_c('v-row',{staticStyle:{"text-align":"end"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.applicationDateFilter = null)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.applicationDateFilter)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"application-search-query application-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{attrs:{"options":_vm.options,"headers":_vm.tableColumns,"items":_vm.applicationListTable,"item-class":_vm.applicationItemClass,"server-items-length":_vm.totalApplicationListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"admin-list-item-show-more-actions",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"admin-list-item-detail",attrs:{"to":{ name: 'applications-view', params: { id: item.id } }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c('v-list-item',{staticClass:"admin-list-item-delete",on:{"click":function($event){return _vm.deleteApplication(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }