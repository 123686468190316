<template>
  <div id="application-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Applications list </v-card-title>

      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-dialog ref="dialog" v-model="applicationDateModal" :return-value.sync="applicationDateFilter" width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="applicationDateFilter"
                class="application-date-filter"
                label="Select Application date"
                outlined
                dense
                clearable
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="applicationDateFilter" class="event-date-picker" scrollable range>
              <v-row style="text-align: end">
                <v-col cols="12">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.dialog.save(applicationDateFilter = null)"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(applicationDateFilter)"> OK </v-btn>
                </v-col>
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="application-search-query application-search me-3 mb-4"
        >
        </v-text-field>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :options.sync="options"
        :headers="tableColumns"
        :items="applicationListTable"
        :item-class="applicationItemClass"
        :server-items-length="totalApplicationListTable"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="admin-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="admin-list-item-detail" :to="{ name: 'applications-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="admin-list-item-delete" @click="deleteApplication(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline } from '@mdi/js';
import store from '@/store';
import Vue from 'vue';
import useApplicationsList from '@/views/shared/applications/useApplicationsList';

export default {
  setup() {
    const {
      applicationDateFilter,
      applicationDateModal,
      applicationListTable,
      tableColumns,
      searchQuery,
      loading,
      options,
      totalApplicationListTable,
      fetchApplications,
    } = useApplicationsList();

    const deleteApplication = application => {
      Vue.$confirm({
        message: `Are you sure you want to delete ${application.name}?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store
              .dispatch('application/deleteApplication', { id: application.id })
              .then(() => fetchApplications())
              .catch(() => {
                Vue.notify({
                  type: 'error',
                  title: 'Application',
                  text: 'Cannot delete application',
                });
              });
          }
        },
      });
    };

    const applicationItemClass = () => {
      return 'application-item';
    };

    return {
      applicationDateFilter,
      applicationDateModal,
      applicationListTable,
      tableColumns,
      searchQuery,
      totalApplicationListTable,
      loading,
      options,
      fetchApplications,
      deleteApplication,
      applicationItemClass,
      icons: {
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
      },
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
